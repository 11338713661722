import React from "react";
import { Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import { customDateRanges, getDateObject } from "app/popleads/app/functions/util";

const DateDropdown = ({ selectedDate, setSelectedDate }) => {
    return (
        <Dropdown id="datePickerDropdown" className="d-none d-xxl-block" align="end">
            <Dropdown.Toggle
                id="kt_filter_toggle"
                variant="outline-primary"
                className="ml-4 hide-chevron font-weight-normal"
            >
                <span
                    className={selectedDate?.dateString === "All Time" ? "disable-color" : ""}
                    id="kt_dashboard_daterangepicker_title3"
                >
                    {selectedDate?.dateString}
                </span>
                <span
                    id="kt_dashboard_daterangepicker3"
                    data-toggle="tooltip"
                    title="Select dashboard daterange"
                    data-placement="left"
                ></span>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="dropdown-menu dropdown-menu-xl px-4 py-6 dropdown-menu-anim-up"
                style={{ width: "max-content" }}
            >
                <DateRangePicker
                    className={styles.rdrDateRangePickerWrapper}
                    ranges={[selectedDate]}
                    staticRanges={customDateRanges(new Date(null), new Date())}
                    onChange={(item) => {
                        const modifiedDates = getDateObject(item.selection);
                        setSelectedDate(modifiedDates);
                        // DOM manipulation to highlight "All Time"
                        const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
                        if (range && !item.selection.startDate) {
                            range.classList.add("rdrStaticRangeSelected");
                        } else {
                            range.classList.remove("rdrStaticRangeSelected");
                        }
                    }}
                    inputRanges={[]}
                    direction="vertical"
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DateDropdown;
